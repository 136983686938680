import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import * as styles from './Layout.module.scss';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet titleTemplate="%s | Creech" defaultTitle="Creech" htmlAttributes={{lang: 'en'}}>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicons/apple-touch-icon.png" />
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#f6e001" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="description" content="creech Studio" />
        <meta name="image" content="https://creech.co/logos/creech-og-image.png" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creech" />
        <meta property="og:url" content="https://creech.co" />
        <meta property="og:title" content="Creech" />
        <meta property="og:description" content="Creech Studio" />
        <meta property="og:image" content="https://creech.co/logos/creech-og-image.png" />
        <meta property="fb:app_id" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@creech" />
        <meta name="twitter:title" content="Creech" />
        <meta name="twitter:description" content="Creech Studio" />
        <meta name="twitter:image" content="https://creech.co/logos/creech-twitter-image.png" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-W9JP7QM64Q"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(...args) {
            dataLayer.push(...args);
          }
          gtag('js', new Date());
          gtag('config', 'G-W9JP7QM64Q');
        `}</script>
        <script type="application/ld+json">
          {`[
            {
              "@context":"http://schema.org",
              "@type":"WebSite",
              "@id":"https://creech.co/",
              "url":"https://creech.co/",
              "name":"Creech",
              "alternateName":"creech"
            }
          ]`}
        </script>
      </Helmet>
      <main className={styles.root}>
        {children}
      </main>
      <noscript>
        <style dangerouslySetInnerHTML={{ __html: '.no-js { display: initial; }' }} />
      </noscript>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;

import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './TextBlock.module.scss';

const TextBlock = ({ children }) => {
  return (
    <section className={styles.root}>
      {children}
    </section>
  );
};

TextBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default TextBlock;
